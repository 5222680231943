
import React from "react"
const AboutPage = () => (
    <main>
        <h1>About the Author</h1>
      <a href={'https://www.amazon.com'}>
        <p>Welcome to my Gatsby site.</p>
      </a>
    </main>
)
export default AboutPage